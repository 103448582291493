import React from 'react';
import styled from 'styled-components';
import { snakeCase, upperFirst, kebabCase } from 'lodash';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import * as BaseComponents from '@comicrelief/component-library';

export const backgroundColours = ['red', 'black', 'deep_violet']; // If background-color equal to one of this colour change text colour to white

const changeColor = background => (background && (background.includes('dark') || backgroundColours.indexOf(background) > -1) ? 'white' : 'black');
const changeButtonColor = (background) => {
  if (background && background.includes('dark')) {
    return 'white';
  }
  if (background && (background.includes('light') || background.includes('white'))) {
    return 'black';
  }
  return 'transparent';
};
const changeLinkColor = background => (background && (background.includes('dark') || background.includes('red') || background.includes('deep_violet') || background.includes('black')) ? 'standard_white' : 'standard');
// Allow overriding CL components to add extra/ modify props
const Components = { ...BaseComponents };
const {
  Text, Link, whiteListed, Picture, RichText,
} = Components;

const MarkupVideo = styled(RichText)`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
`;

const Image = styled(Picture)`
  img {
    width: auto;
    max-width: 100%;
    margin: auto;
  }
`;

const List = styled.ul`
  text-align: left;
`;

const OrderedList = styled.ol`
  text-align: left;
`;

const isAnchor = (link) => {
  if (link.includes('#')) {
    return true;
  }
  return false;
};

const options = {
  renderMark: {
    [MARKS.BOLD]: (children) => {
      const fontSize = options.type === 'card' ? 'sm' : 'l';

      return (
        <Text weight="bold" size={fontSize}>
          {children}
        </Text>
      );
    },
  },
  [MARKS.ITALIC]: children => <i>{children}</i>,
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <Text
        tag="h1"
        id={`${kebabCase(node.content[0].value)}-anchor`}
        color={changeColor(options.background)}
        size="super"
        uppercase
        family="Anton"
        weight="normal"
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Text
        tag="h2"
        id={`${kebabCase(node.content[0].value)}-anchor`}
        color={changeColor(options.background)}
        size="xxl"
        height="3rem"
        uppercase
        family="Anton"
        weight="normal"
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Text
        tag="h3"
        id={`${kebabCase(node.content[0].value)}-anchor`}
        color={changeColor(options.background)}
        size="xl"
        height="2rem"
        uppercase
        family="Anton"
        weight="normal"
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Text
        tag="h4"
        id={`${kebabCase(node.content[0].value)}-anchor`}
        color={changeColor(options.background)}
        size="xl"
        height="2rem"
        uppercase
        family="Anton"
        weight="normal"
      >
        {children}
      </Text>
    ),

    [BLOCKS.PARAGRAPH]: (node, children) => {
      const isCard = options.type === 'card';
      const isDefaultPage = options.type === 'defaultPage';
      const fontSize = isCard ? 'sm' : 'l';
      if (children.length === 1 && children[0] === '') {
        return null;
      }

      let childrenCopy = children;

      /* Determine if this Link is the only thing in the paragraph,
      *  NOT an anchor link and NOT in a Card */
      if (childrenCopy[0] === '' && childrenCopy[2] === '' && childrenCopy[1].props.href !== undefined && !isAnchor(childrenCopy[1].props.href) && !isCard && !isDefaultPage) {
        // If so, change from the default 'standard' link to a button with the appropriate colour
        childrenCopy = React.cloneElement(childrenCopy[1], { type: 'button', color: changeButtonColor(options.background) });
      }

      return (
        <Text tag="p" color={changeColor(options.background)} size={fontSize}>
          {childrenCopy}
        </Text>
      );
    },
    [BLOCKS.QUOTE]: (node, children) => {
      // quote is wrapping paragraph and paragraph overrides quote style
      const text = children[0].props.children[0];

      return (
        <Text tag="p" color={changeColor(options.background)} size="xxl" weight="bold">
          {text}
        </Text>
      );
    },
    [BLOCKS.UL_LIST]: (node, children) => <List>{children}</List>,
    [BLOCKS.OL_LIST]: (node, children) => <OrderedList>{children}</OrderedList>,
    [BLOCKS.LIST_ITEM]: (node, children) => {
      const link = children.filter(child => child)[0].props.children.props;
      const isCard = options.type === 'card';
      const fontSize = isCard ? 'sm' : 'l';
      if (link) {
        const copy = link.children[0];
        const { href, target, color } = link;

        return (
          <li>
            <Text size={fontSize} tag="span" color={color}>
              <Link
                type={changeLinkColor(options.background)}
                href={href}
                target={target}
              >
                {copy}
              </Link>
            </Text>
          </li>
        );
      }
      return <li>{children}</li>;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const externaLink = node.data && node.data.uri;
      const isInternalLink = whiteListed(externaLink);
      const isInternalPath = externaLink.charAt(0) === '/';
      const target = isInternalLink || isAnchor(externaLink) ? 'self' : 'blank';

      if (isInternalLink || isInternalPath) {
        return (
          <Link type={changeLinkColor(options.background)} href={externaLink}>
            {children}
          </Link>
        );
      }

      return (
        <Link
          type={changeLinkColor(options.background)}
          href={externaLink}
          target={target}
        >
          {children}
        </Link>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const type = node.data.target.sys.contentType
        && node.data.target.sys.contentType.sys ? node.data.target.sys.contentType.sys.id : null;
      let link = '';

      if (type) {
        if (type === 'newsArticle') {
          link = `/news/${node.data.target.fields.path['en-GB']}`;
        } else {
          link = type !== 'componentLink'
            ? `/${node.data.target.fields.path['en-GB']}`
            : node.data.target.fields.url['en-GB'];
        }
      }

      if (link === '') {
        return (
          <Text color="red">undefined link</Text>
        );
      }

      return (
        <Link type={changeLinkColor(options.background)} href={link}>
          {children}
        </Link>
      );
    },
    [INLINES.ASSET_HYPERLINK]: (node) => {
      const { fields } = node.data.target;
      const linkText = node.content[0].value;

      return (
        <span>
          <Link
            type={changeLinkColor(options.background)}
            rel="noopener noreferrer"
            href={fields.file['en-GB'].url}
            target="blank"
          >
            {linkText}
          </Link>
        </span>
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { fields } = node.data.target;

      const thisFile = fields.file['en-GB'];
      const thisTitle = fields.title['en-GB'];
      const thisDescription = fields.description ? fields.description['en-GB'] : thisTitle;
      const thisFileURL = thisFile.url;

      // Determine the file-type
      const isImage = thisFile.details.image !== undefined;

      if (isImage) {
        return (
          <Image
            alt={thisDescription}
            image={thisFileURL}
            objectFit
          />
        );
      }

      // Else, renderer the file as a link
      return (
        <span>
          <Link
            type={changeLinkColor(options.background)}
            rel="noopener noreferrer"
            href={thisFileURL}
            target="blank"
          >
            {thisTitle}
          </Link>
        </span>
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { fields, sys } = node.data.target;
      const componentId = upperFirst(sys.contentType.sys.id);
      // skip rendering if component does not exist and it is not HtmlCode
      if (componentId === 'HtmlCode') {
        const snippet = fields.snippetCode && fields.snippetCode['en-GB'];
        const isIframe = ((snippet).includes('iframe'));
        if (isIframe) {
          return <Text tag="p"><MarkupVideo markup={fields.snippetCode['en-GB']} /></Text>;
        }
        return <Text tag="p"><RichText markup={fields.snippetCode['en-GB']} /></Text>;
      } if (!Components[componentId]) {
        return null;
      }
      const Component = Components[componentId];
      // assuming that content entry fields correspond to props in that component,
      // those fields then are passed to the component after some processing,
      // we fetch 'en-GB' field value first, then do extra processing if needed,
      // then rich text fields are parsed here using `documentToReactComponents`,
      // and entries are processed by calling `getValues` recursively,
      // except for colour entry which is handled separately using title field directly.
      const getValues = (entryFields) => {
        const values = {};
        Object.keys(entryFields).map((fieldName) => {
          const value = entryFields[fieldName]['en-GB'];
          values[fieldName] = value;
          if (value) {
            if (value.fields) {
              switch (value.sys.contentType.sys.id) {
                case 'colour':
                  values[fieldName] = snakeCase(getValues(value.fields).title);
                  break;
                default:
                  values[fieldName] = getValues(value.fields);
              }
            } else if (value.nodeType === 'document') {
              options.type = componentId;
              values[fieldName] = documentToReactComponents(value, options);
            }
          }
          return true;
        });
        return values;
      };
      return (
        <span>
          <Component backgroundColor={options.background} {...getValues(fields)} />
        </span>
      );
    },
  },
};

export default function renderRichText(richText, color, type) {
  options.background = color;
  options.type = type;

  return documentToReactComponents(richText, options);
}
